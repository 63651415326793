import { LogLevel } from '../app/shared/service/logger/log-level.enum';

export const environment = {
    
    production: false,
    profile: 'staging',
    displaymetrics: true,
    LOG_LEVEL: LogLevel.DEBUG,
    access_token: 'fc3a7488-0217-4077-a5e5-b26ea0961964',

    authUrl: 'https://staging.sensori.online/api',    
    apiUrl: 'https://staging.sensori.online/api/coreapi',
    apiProjectsUrl: 'https://staging.sensori.online/api',
    apiCoreUrl: 'https://staging.sensori.online/api/coreapi',
    celeryapi: 'https://staging.sensori.online/api/celeryapi',

};
